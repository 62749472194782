import { createStore } from 'vuex'

// 使用 webpack 的 require.context 导入 Markdown 文件
const markdownFiles = require.context('../posts/', true, /\.md$/)

// 日期格式化
function formatDate(dateString) {
  const date = new Date(dateString)
  return date.toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}


// 改进图片路径处理函数
function getImageUrl(imagePath) {
  if (!imagePath) return ''
  
  // 处理外部链接
  if (imagePath.startsWith('http')) {
    return imagePath
  }
  
  try {
    // 使用 require.context 预加载所有图片
    const imagesContext = require.context('../posts/images/', true, /\.(png|jpe?g|gif|webp|svg)$/i)
    
    // 标准化图片路径
    const normalizedPath = imagePath.startsWith('/') ? imagePath.slice(1) : imagePath
    
    // 查找匹配的图片
    const imageKey = imagesContext.keys().find(key => {
      // 移除 './' 前缀并比较路径
      const contextKey = key.replace(/^\.\//, '')
      return contextKey === normalizedPath
    })
    
    if (imageKey) {
      return imagesContext(imageKey)
    }
    
    throw new Error(`Image not found: ${imagePath}`)
  } catch (e) {
    console.warn(`Image loading error for ${imagePath}:`, e)
    return '' // 或者返回默认占位图片路径
  }
}


// 改进 Markdown 内容中的图片路径处理
function processMarkdownImages(content) {
  return content.replace(
    /!\[(.*?)\]\((.*?)\)/g,
    (match, alt, path) => {
      // 处理外部链接
      if (path.startsWith('http')) {
        return match
      }
      
      try {
        const imageUrl = getImageUrl(path)
        return imageUrl ? `![${alt}](${imageUrl})` : match
      } catch (e) {
        console.warn(`Failed to process image in markdown: ${path}`)
        return match
      }
    }
  )
}

// Markdown 文件处理
function processMarkdownFiles() {
  return markdownFiles.keys().map((path, index) => {
    const slug = path.replace(/^\.\//, '').replace(/\.md$/, '')
    const { attributes, html } = markdownFiles(path)

    return {
      id: index + 1,
      slug,
      title: attributes.title,
      date: formatDate(attributes.date),
      summary: attributes.summary,
      coverImage: getImageUrl(attributes.coverImage),
      pinned: attributes.pinned || false,
      content: processMarkdownImages(html)
    }
  })
}

const store = createStore({
  state() {
    return {
      posts: processMarkdownFiles()
    }
  },
  getters: {
    getPostById: (state) => (id) => {
      return state.posts.find(post => post.id === parseInt(id))
    },
    getPostBySlug: (state) => (slug) => {
      return state.posts.find(post => post.slug === slug)
    },
    getAllPosts: (state) => {
      return state.posts
    }
  },
  mutations: {
    UPDATE_POST(state, { id, post }) {
      const index = state.posts.findIndex(p => p.id === id)
      if (index !== -1) {
        state.posts[index] = { ...state.posts[index], ...post }
      }
    }
  },
  actions: {
    updatePost({ commit }, payload) {
      commit('UPDATE_POST', payload)
    }
  }
})

export default store
