<!-- BlogPost.vue -->
<template>
  <article class="blog-post-card" :class="{ 'blog-post-pinned': post.pinned }" @click="$emit('click')">
    <!-- 添加置顶标识 -->
    <div class="pin-badge" v-if="post.pinned">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="12" y1="17" x2="12" y2="22"/>
        <path d="M5 17h14v-1.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V6h1a2 2 0 0 0 0-4H8a2 2 0 0 0 0 4h1v4.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24Z"/>
      </svg>
      <span>置顶</span>
    </div>
    
    <div class="post-image" >
      <img 
        v-if="post.coverImage"
        :src="post.coverImage" 
        :alt="post.title"
        @error="handleImageError"
        loading="lazy"
      >
      <img 
        v-else 
        src="/placeholder-image.gif" 
        alt="Placeholder Image" 
        loading="lazy"
      >
    </div>
    <div class="post-content">
      <h2 class="post-title">{{ post.title }}</h2>
      <div class="post-meta">
        <span class="post-date">{{ post.date }}</span>
      </div>
      <p class="post-summary">{{ post.summary }}</p>
      <div class="post-footer">
        <span class="read-more">阅读全文 →</span>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'BlogPostItem',
  props: {
    post: {
      type: Object,
      required: true,
      default: () => ({

        coverImage: '',
        summary: ''
      }),
      validator: function(obj) {
        return obj && 
          // 标题可以是空字符串，但必须是字符串
          (typeof obj.title === 'string') && 
          // 日期可以是空字符串，但必须是字符串
          (typeof obj.date === 'string') && 
          // pinned 是可选的，如果存在必须是布尔值
          (!('pinned' in obj) || typeof obj.pinned === 'boolean') && 
          // coverImage 可以是字符串或 undefined
          (typeof obj.coverImage === 'string' || obj.coverImage === undefined) && 
          // summary 可以是空字符串，但必须是字符串
          (typeof obj.summary === 'string')
      }
    }
  },
  methods: {
    handleImageError(e) {
      e.target.src = '/placeholder-image.gif'
    }
    
    
  }
}
</script>

<style scoped>
/* 修正 - 为 blog-post-card 添加 position: relative */
.blog-post-card {
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #eef2f7;
  position: relative; /* 添加相对定位 */
}

.blog-post-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 置顶标识样式 */
.pin-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(66, 184, 131, 0.9);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.pin-badge svg {
  width: 14px;
  height: 14px;
}

.blog-post-card {
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #eef2f7;
  position: relative;
}

.blog-post-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-post-card:hover .post-image img {
  transform: scale(1.05);
}

.post-content {
  padding: 1.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #ffffff 0%, #fafbfc 100%);
}

.post-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0 0 0.5rem 0;
  line-height: 1.4;
}

.post-meta {
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 0.75rem;
}

.post-summary {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex: 1;
  font-size: 0.9375rem;
}

.post-footer {
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid #edf2f7;
}

.read-more {
  color: #42b883;
  font-weight: 500;
  font-size: 0.875rem;
}

.read-more:hover {
  color: #3aa876;
}
/* 中分辨率：调整为两列布局 */
@media (max-width: 1024px) {
  .blog-post-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 小分辨率：调整为一列布局 */
@media (max-width: 768px) {
  .blog-post-container {
    grid-template-columns: 1fr;
  }
}
</style>